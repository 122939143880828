define("ember-svg-jar/inlined/smile/newplan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#757575\" d=\"M25.333 4.001H6.666a2.67 2.67 0 00-2.667 2.667v18.667a2.669 2.669 0 002.667 2.667h9.333c1.092 0 2.447-.56 3.219-1.333l7.448-7.447c.773-.773 1.333-2.127 1.333-3.219V6.67a2.67 2.67 0 00-2.667-2.668zM6.667 25.335V6.668h18.667v9.333h-5.333c-2.205 0-4 1.795-4 4v5.333H6.668zm12-1.886v-3.448c0-.736.599-1.333 1.333-1.333h3.448l-4.781 4.781z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});