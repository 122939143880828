define("ember-svg-jar/inlined/smile/enable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#757575\" d=\"M26.667 29.332H5.334a2.669 2.669 0 01-2.667-2.667V5.332a2.67 2.67 0 012.667-2.667h21.333a2.67 2.67 0 012.667 2.667v21.333a2.669 2.669 0 01-2.667 2.667zm-21.334-24v21.333h21.329l.004-21.333H5.333z\"/><path fill=\"#757575\" d=\"M10.667 7.999v16h10.667v-16H10.667zm2.666 13.333v-5.333h5.333v5.333h-5.333z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});