define("ember-svg-jar/inlined/smile/platform-icons/bigcommerce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#34313F\" fill-rule=\"nonzero\"><path d=\"M8.41 9.067h2.009c.571 0 .933-.315.933-.82 0-.476-.362-.818-.933-.818h-2.01c-.066 0-.123.057-.123.114v1.41c.01.066.057.114.124.114zM8.41 12.314h2.076c.638 0 1.019-.324 1.019-.895 0-.495-.362-.895-1.02-.895H8.41c-.067 0-.124.057-.124.114v1.552c.01.077.057.124.124.124z\"/><path d=\"M15.762.038L9.819 5.962h.971c1.515 0 2.41.952 2.41 1.99 0 .82-.552 1.42-1.143 1.686-.095.038-.095.172.01.21.685.266 1.171.98 1.171 1.819 0 1.18-.79 2.123-2.324 2.123h-4.21c-.066 0-.123-.057-.123-.114V9.19L.057 15.686c-.086.085-.028.238.095.238h15.686a.106.106 0 00.105-.105V.124c.028-.105-.105-.162-.181-.086z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});