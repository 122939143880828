define("ember-uploader/uploaders/s3", ["exports", "jquery", "rsvp", "@ember/object", "@ember/runloop", "ember-uploader/uploaders/uploader", "@ember/polyfills"], function (_exports, _jquery, _rsvp, _object, _runloop, _uploader, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _uploader.default.extend({
    /**
     * Target url used to request a signed upload policy
     *
     * @property url
     */
    signingUrl: '/sign',
    /**
     * request method for signing
     *
     * @property method
     */
    signingMethod: 'GET',
    /**
     * Boolean property changed to true upon signing start and false upon
     * signing end
     *
     * @property isSigning
     */
    isSigning: false,
    /**
     * Request signed upload policy and upload file(s) and any extra data
     *
     * @param  {object} file  A file object
     * @param  {object} extra Extra data to be sent with the upload
     * @return {object} Returns a Ember.RSVP.Promise wrapping the signing
     * request object
     */
    upload(file, extra = {}) {
      return this.sign(file, extra).then(json => {
        let url;
        (0, _object.set)(this, 'isUploading', true);
        if (json.endpoint) {
          url = json.endpoint;
          delete json.endpoint;
        } else if (json.region) {
          url = `https://s3-${json.region}.amazonaws.com/${json.bucket}`;
          delete json.region;
        } else {
          url = `https://${json.bucket}.s3.amazonaws.com`;
        }
        return this.ajax(url, this.createFormData(file, json));
      });
    },
    /**
     * Request signed upload policy
     *
     * @param  {object} file  A file object
     * @param  {object} extra Extra data to be sent with the upload
     * @return {object} Returns a Ember.RSVP.Promise wrapping the signing
     * request object
     */
    sign(file, extra = {}) {
      const url = (0, _object.get)(this, 'signingUrl');
      const method = (0, _object.get)(this, 'signingMethod');
      const signingAjaxSettings = (0, _object.get)(this, 'signingAjaxSettings');
      extra.name = file.name;
      extra.type = file.type;
      extra.size = file.size;
      const settings = (0, _polyfills.assign)({}, {
        contentType: 'application/json',
        dataType: 'json',
        data: method.match(/get/i) ? extra : JSON.stringify(extra),
        method,
        url
      }, signingAjaxSettings);
      (0, _object.set)(this, 'isSigning', true);
      return new _rsvp.Promise((resolve, reject) => {
        settings.success = json => {
          (0, _runloop.run)(null, resolve, this.didSign(json));
        };
        settings.error = (jqXHR, responseText, errorThrown) => {
          (0, _runloop.run)(null, reject, this.didErrorOnSign(jqXHR, responseText, errorThrown));
        };
        _jquery.default.ajax(settings);
      });
    },
    /**
     * Triggers didErrorOnSign event and sets isSigning to false
     *
     * @param {object} jqXHR jQuery XMLHttpRequest object
     * @param {string} textStatus The status code of the error
     * @param {object} errorThrown The error caused
     * @return {object} Returns the jQuery XMLHttpRequest
     */
    didErrorOnSign(jqXHR, textStatus, errorThrown) {
      (0, _object.set)(this, 'isSigning', false);
      this.trigger('didErrorOnSign');
      this.didError(jqXHR, textStatus, errorThrown);
      return jqXHR;
    },
    /**
     * Triggers didSign event and returns the signing response
     *
     * @param {object} response The signing response
     * @return {object} The signing response
     */
    didSign(response) {
      this.trigger('didSign', response);
      return response;
    }
  });
});