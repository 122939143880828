define("ember-svg-jar/inlined/smile/platform-icons/custom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M0 0h16v16H0z\"/><g fill=\"#919EAB\"><path d=\"M6.667 14.667c0 .889-.445 1.333-1.334 1.333S4 15.556 4 14.667c0-.89.444-1.334 1.333-1.334.89 0 1.334.445 1.334 1.334zM12 14.667c0 .889-.444 1.333-1.333 1.333-.89 0-1.334-.444-1.334-1.333 0-.89.445-1.334 1.334-1.334S12 13.778 12 14.667zM13.333 2.666c-.31 0-.579.214-.65.517L12.034 6H8.667v1.333h3.059l-.923 4H5.187l-.666-2.666H3.146l.874 3.495c.074.297.34.505.647.505h6.666c.311 0 .58-.214.65-.517L13.863 4H16V2.665h-2.667z\"/><path d=\"M3.335 8h1.333v-.734c.425-.087.82-.256 1.169-.488l.522.522.942-.943-.522-.522c.232-.35.401-.744.488-1.168H8V3.333h-.734a3.324 3.324 0 00-.489-1.169l.523-.523L6.358.7l-.523.523A3.308 3.308 0 004.667.734V0H3.334v.734c-.425.087-.82.257-1.169.488L1.643.7l-.942.943.522.522a3.3 3.3 0 00-.488 1.168H0v1.334h.735c.087.424.256.819.488 1.169L.7 6.359l.943.942.521-.523c.35.231.745.401 1.17.487v.736zm-.668-4a1.333 1.333 0 112.666 0 1.333 1.333 0 01-2.666 0z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});