define("ember-svg-jar/inlined/polaris/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M30.4 17.6H1.6a1.6 1.6 0 110-3.2h28.8a1.6 1.6 0 110 3.2zm0-11.2H1.6a1.6 1.6 0 110-3.2h28.8a1.6 1.6 0 110 3.2zm0 22.4H1.6a1.6 1.6 0 110-3.2h28.8a1.6 1.6 0 110 3.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  };
});