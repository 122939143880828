define("ember-svg-jar/inlined/smile/wix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#000\" fill-rule=\"nonzero\"><path d=\"M43.95.521C42.53 1.266 42 2.524 42 6c0 0 .72-.696 1.786-1.084a7.544 7.544 0 001.827-.992C46.814 3.044 47 1.91 47 0c0 0-1.958-.053-3.05.52M33.357 4.294l-4.114 16.02L25.82 7.204c-.333-1.394-.934-3.117-1.885-4.283-1.211-1.485-3.673-1.578-3.934-1.578-.263 0-2.725.093-3.935 1.578-.951 1.166-1.552 2.89-1.886 4.283l-3.422 13.112L6.644 4.294S6.28 2.539 5.038 1.438C3.022-.347 0 .034 0 .034l7.88 29.941s2.6.19 3.9-.478c1.708-.875 2.521-1.55 3.556-5.624.922-3.632 3.499-14.3 3.739-15.06.12-.375.27-1.272.926-1.272.67 0 .808.897.924 1.272.237.761 2.817 11.428 3.74 15.06 1.034 4.074 1.848 4.749 3.556 5.624 1.3.667 3.9.478 3.9.478L40 .035s-3.021-.382-5.037 1.403c-1.243 1.1-1.606 2.856-1.606 2.856zM46.997 5s-.46.763-1.507 1.392c-.674.405-1.32.678-2.013 1.035C42.315 8.025 42 8.692 42 9.709v20.244s1.854.251 3.067-.412c1.56-.854 1.919-1.678 1.933-5.387V6.08h-.003V5zM67.684 15.007L77.894.16s-4.308-.717-6.442 1.182c-1.365 1.215-2.893 3.4-2.893 3.4l-3.756 5.295c-.182.275-.422.575-.802.575-.38 0-.62-.3-.801-.575l-3.758-5.295s-1.526-2.185-2.892-3.4C54.417-.558 50.107.16 50.107.16l10.21 14.847-10.184 14.8s4.489.555 6.623-1.344c1.365-1.216 2.686-3.19 2.686-3.19l3.758-5.295c.18-.275.42-.575.801-.575.38 0 .62.3.802.575l3.756 5.294s1.4 1.975 2.765 3.191c2.134 1.9 6.543 1.345 6.543 1.345l-10.183-14.8z\"/></g>",
    "attrs": {
      "width": "78",
      "height": "30",
      "viewBox": "0 0 78 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});