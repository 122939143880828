define("ember-svg-jar/inlined/polaris/duplicate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 12h8V4H8v8zm4 4H4V8h2v5a1 1 0 001 1h5v2zm5-14H7a1 1 0 00-1 1v3H3a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1v-3h3a1 1 0 001-1V3a1 1 0 00-1-1z\" fill=\"#31373D\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});