define("ember-uploader/components/file-field", ["exports", "@ember/utils", "@ember/object/evented", "@ember/component"], function (_exports, _utils, _evented, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_evented.default, {
    tagName: 'input',
    type: 'file',
    attributeBindings: ['name', 'disabled', 'form', 'type', 'accept', 'autofocus', 'required', 'multiple'],
    multiple: false,
    change(event) {
      const input = event.target;
      if (!(0, _utils.isEmpty)(input.files)) {
        this.trigger('filesDidChange', input.files);
      }
    }
  });
});