define("ember-svg-jar/inlined/smile/logo-yellow-white-bg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 19.001C0 8.508 8.514 0 19.001 0h85.696c10.493 0 19 8.514 19 19.001v85.696c0 10.493-8.513 19-19 19H19c-10.493 0-19.001-8.513-19.001-19V19zm83.276 31h-3.729c-.742 0-1.38.265-1.916.804-.487.489-.793 1.065-.81 1.724-.01.402-.067 2.759-.2 4.247-.404 4.316-1.833 7.787-4.287 10.414-2.434 2.61-5.877 3.922-10.33 3.944-4.457-.02-7.902-1.332-10.338-3.944-2.454-2.627-3.883-6.098-4.287-10.414-.133-1.488-.19-3.845-.2-4.247-.017-.659-.323-1.235-.81-1.724-.536-.54-1.174-.805-1.916-.805h-3.73c-.742 0-1.38.266-1.919.805-.484.489-.804.973-.804 1.821 0 0 .131 3.878.202 4.652.604 6.88 2.973 12.357 7.111 16.435 4.097 4.042 9.632 6.08 16.604 6.117l.166.001c6.972-.038 12.507-2.076 16.604-6.118 4.138-4.078 6.507-9.555 7.111-16.435.07-.774.202-4.651.202-4.651 0-.85-.32-1.333-.804-1.822-.54-.54-1.177-.805-1.92-.805z\" fill-rule=\"nonzero\" fill=\"#FFC828\"/>",
    "attrs": {
      "width": "124",
      "height": "124",
      "viewBox": "0 0 124 124",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});