define("ember-svg-jar/inlined/smile/reset-customer-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#637381\" fill-rule=\"evenodd\"><path d=\"M1.44 14.667h7.788c-.399-1.206-1.89-2-3.895-2s-3.495.794-3.894 2zM10.665 16H0v-.667c0-2.355 2.193-4 5.333-4s5.333 1.645 5.333 4V16zM6.481 8.667A1.328 1.328 0 014 8c0-.736.598-1.333 1.333-1.333.244 0 .47.07.667.185V5.428a2.647 2.647 0 00-.667-.095A2.67 2.67 0 002.666 8a2.67 2.67 0 002.667 2.667c1.24 0 2.274-.854 2.572-2H6.481zM12 0C9.794 0 8 1.794 8 4h1.333A2.67 2.67 0 0112 1.333 2.67 2.67 0 0114.667 4 2.67 2.67 0 0112 6.667a2.631 2.631 0 01-1.853-.746l-.032-.036 1.218-1.218H8V8l1.171-1.171A3.972 3.972 0 0012 8c2.206 0 4-1.794 4-4s-1.794-4-4-4\"/></g>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});