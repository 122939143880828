define("ember-svg-jar/inlined/polaris/cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.414 10l6.293-6.293a.999.999 0 10-1.414-1.414L10 8.586 3.707 2.293a.999.999 0 10-1.414 1.414L8.586 10l-6.293 6.293a.999.999 0 101.414 1.414L10 11.414l6.293 6.293a.997.997 0 001.414 0 .999.999 0 000-1.414L11.414 10z\" fill=\"#31373D\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});