define("ember-svg-jar/inlined/smile/billing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#757575\" d=\"M26.035 2.867a1.335 1.335 0 00-1.299-.059l-4.64 2.32-3.357-2.237a1.331 1.331 0 00-1.479 0l-3.355 2.237-4.641-2.32A1.332 1.332 0 005.334 4v24a1.336 1.336 0 001.929 1.194l4.641-2.32 3.356 2.236c.448.3 1.031.3 1.479 0l3.356-2.236 4.641 2.32A1.333 1.333 0 0026.667 28V4c0-.463-.24-.891-.632-1.133zM24 25.843l-3.404-1.701a1.33 1.33 0 00-1.336.083L16 26.398l-3.26-2.173a1.339 1.339 0 00-1.336-.083L8 25.843V6.159l3.404 1.7c.427.213.937.183 1.336-.084L16 5.603l3.26 2.172c.397.267.909.297 1.336.084L24 6.159v19.684z\"/><path fill=\"#757575\" d=\"M9.333 10.667H16v2.667H9.333v-2.667zM20 10.667h2.667v2.667H20v-2.667zM9.333 14.667h5.333v2.667H9.333v-2.667zM20 14.667h2.667v2.667H20v-2.667zM9.333 18.667h8v2.667h-8v-2.667zM20 18.667h2.667v2.667H20v-2.667z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});