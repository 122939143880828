define("ember-component-css/pod-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "account/billing-card": "__08fd2",
    "account/details-card": "__49c06",
    "account/plan-card": "__e6fed",
    "account/platform-debugging-tool": "__b68d7",
    "account/profile-card": "__752dd",
    "account/resource-list/item": "__2e81a",
    "account/status-card": "__e38db",
    "account/summary-card": "__1cde2",
    "admin/resource-list/item": "__95333",
    "app-top-bar-profile-trigger": "__1c422",
    "billing-discount/data-table": "__310dd",
    "centered-spinner": "__a3cc5",
    "new-integration/list-card": "__7a523",
    "nudge/list-card": "__ec792",
    "partner-account/data-table/search-field": "__d5c89",
    "partner-account/managed-accounts-card": "__0e58d",
    "partner-account/managed-accounts-list": "__460bc",
    "polaris-modals/account-credits": "__52632",
    "polaris-modals/billing-subscription-template/edit": "__f2d37",
    "polaris-modals/two-factor-auth-recovery-codes": "__c8513",
    "polaris-tabs": "__f50ac",
    "status-indicator": "__af876"
  };
});