define("ember-config-service/services/config", ["exports", "@ember/object/proxy", "@ember/application"], function (_exports, _proxy, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let configService = _proxy.default.extend({
    init() {
      this.content = (0, _application.getOwner)(this).factoryFor('config:environment').class;
      this._super(...arguments);
    }
  });
  configService.reopenClass({
    isServiceFactory: true
  });
  var _default = _exports.default = configService;
});