define("ember-api-actions/utils/member-action", ["exports", "ember-api-actions/utils/build-url", "ember-api-actions/utils/types"], function (_exports, _buildUrl, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = instanceOp;
  function instanceOp(options) {
    return function runInstanceOp(payload) {
      const recordClass = (0, _buildUrl._getModelClass)(this);
      const modelName = (0, _buildUrl._getModelName)(recordClass);
      const store = (0, _buildUrl._getStoreFromRecord)(this);
      const {
        ajaxOptions,
        path,
        before,
        after,
        type = 'put',
        urlType = 'updateRecord'
      } = options;
      const requestType = (0, _types.strictifyHttpVerb)(type);
      const adapter = store.adapterFor(modelName);
      const fullUrl = (0, _buildUrl.buildOperationUrl)(this, path, urlType);
      const data = before && before.call(this, payload) || payload;
      return adapter.ajax(fullUrl, requestType, Object.assign(ajaxOptions || {}, {
        data
      })).then(response => {
        if (after && !this.isDestroyed) {
          return after.call(this, response);
        }
        return response;
      });
    };
  }
});