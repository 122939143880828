define("ember-elsewhere/components/to-elsewhere", ["exports", "@ember/component", "@ember/object/internals", "@ember/service", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _internals, _service, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{this.sideEffect}}
  
  */
  {
    "id": "P2vqihzW",
    "block": "[[[1,[30,0,[\"sideEffect\"]]],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "ember-elsewhere/components/to-elsewhere.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (_dec = (0, _service.inject)('ember-elsewhere'), _class = class _class extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "service", _descriptor, this);
    }
    get sideEffect() {
      if (this.args.name) {
        throw new Error(`to-elsewhere takes a "named=" parameter, not "name="`);
      }
      this.service.show((0, _internals.guidFor)(this), this.args.named, this.args.send, this.args.outsideParams, this.args.order);
      return undefined;
    }
    willDestroy() {
      super.willDestroy();
      this.service.clear((0, _internals.guidFor)(this));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "service", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class));
});