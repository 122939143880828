define("ember-svg-jar/inlined/smile/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#757575\" d=\"M20 8V5.333C20 4.598 19.404 4 18.667 4h-5.333c-.737 0-1.333.597-1.333 1.333V8H2.668v2.667h9.333v2.667c0 .737.596 1.333 1.333 1.333h5.333c.737 0 1.333-.596 1.333-1.333v-2.667h9.333V8H20zm-2.667 4h-2.667V6.667h2.667V12zM26.667 18.667c0-.735-.596-1.333-1.333-1.333h-5.333c-.737 0-1.333.599-1.333 1.333v2.667h-16v2.667h16v2.667c0 .737.596 1.333 1.333 1.333h5.333c.737 0 1.333-.596 1.333-1.333v-2.667h2.667v-2.667h-2.667v-2.667zM24 25.333h-2.667V20H24v5.333z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
});